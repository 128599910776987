<template>
  <div>
    <el-dialog title="初审" v-model="val" width="460px" :destroy-on-close="true" @closed="closed">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120">
          <el-form-item label="初审通过" prop="audit_status" class="auf-item">
            <el-select v-model="form.audit_status" @change="whenStatusChanged">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="!form.audit_status" label="不通过原因" prop="audit_desc" class="auf-item">
            <el-input v-model="form.audit_desc" type="textarea" :rows="5" maxlength="300" />
          </el-form-item>

          <el-form-item v-if="form.audit_status" label="复审用户" prop="audit_confirm_uid" class="auf-item">
            <el-select v-model="form.audit_confirm_uid" @change="whenUserChanged">
              <el-option v-for="(item, inx) in users" :key="inx" :label="item.realname" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="flex-center">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="saving" @click="submit">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/commons/popup.mixin.js'
import { mapState } from 'vuex'
const AUDIT_ROLE_AUDIT_CONFIRM = 2
const AUDIT_STATUS_CONFIRM = 1

export default {
  mixins: [mixin],

  props: {
    tracks: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      form: {
        audit_status: AUDIT_STATUS_CONFIRM,
        audit_desc: null,
        audit_confirm_uid: null,
      },
      rules: {
        audit_status: { required: true, message: '请选择是否通过' },
        audit_desc: { required: false, message: '请填写不通过原因' },
        audit_confirm_uid: { required: false, message: '请选择复审用户' },
      },
      saving: false,
      users: []
    }
  },

  computed: {
    ...mapState({
      lastConfirmUid: state => state.tableTrack.lastConfirmUid
    })
  },

  created() {
    this.reloadUsers()
  },

  methods: {
    async reloadUsers() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/user/list'
      })
      this.users = (data || [])
        .filter(p => p.roles && p.roles.indexOf(AUDIT_ROLE_AUDIT_CONFIRM) !== -1)

      let defaultUid = null
      if (this.users.length > 0) {
        if (this.lastConfirmUid) {
          for (const u of this.users) {
            if (u.id === this.lastConfirmUid) {
              defaultUid = u.id
              break
            }
          }
        }
        if (!defaultUid) defaultUid = this.users[0].id
      }
      if (defaultUid) this.form.audit_confirm_uid = defaultUid
    },

    whenStatusChanged() {
      this.rules.audit_desc.required = this.form.audit_status !== AUDIT_STATUS_CONFIRM
      this.rules.audit_confirm_uid.required = this.form.audit_status === AUDIT_STATUS_CONFIRM
    },

    whenUserChanged() {
      this.$store.commit('tableTrack/lastConfirmUid', this.form.audit_confirm_uid)
    },

    submit() {
      this.$refs.form.validate((yes) => {
        if (yes) this.doSubmit()
      })
    },

    async doSubmit() {
      if (this.saving) return
      try {
        this.saving = true
        const feilds = {
          ...this.form
        }
        
        if (feilds.audit_status === AUDIT_STATUS_CONFIRM) {
          feilds.audit_desc = '初审通过'
        } else {
          feilds.audit_confirm_uid = null
        }
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/audit/submit',
          data: {
            ids: this.tracks.map(p => p.id),
            feilds
          }
        })
        this.$emit('done')
        this.$message.success('提交成功')
        this.close()
      } finally {
        this.saving = false
      }
    },
  }
}
</script>

<style>
.import-form-upload{
  width:100%;
}

.import-form-upload-box{
  width:400px;
  height:200px;
}
</style>
