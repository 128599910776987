<template>
  <div class="container layout-container">
    <div class="layout-bar">
      <el-button type="primary" @click="reload()">刷新</el-button>
      <el-button type="primary" :loading="publishing" @click="publish()">同步入库</el-button>
      <el-button type="primary" :loading="lrcTasking" @click="submitLrcTaskBySelecteds()">自动转歌词</el-button>
      <el-button type="primary" @click="startAITasksBySelecteds()">执行AI自动化</el-button>
      <el-button type="primary" @click="showAuditBySelecteds()">初审</el-button>
      <el-button type="primary" @click="showConfrimBySelecteds()">复审</el-button>
      <el-button type="primary" :loading="syncing" @click="syncToProd()">同步到线上</el-button>
      <el-button type="warning" :loading="offlineing" @click="offline()">下架</el-button>
      <el-button type="primary" :loading="onlineing" @click="online()">上架</el-button>
    </div>
    <div class="layout-content" ref="container">
      <el-table
        :data="list" :height="height"
        key="table" border style="width: 100%"
        row-key="id"
        ref="table"
        v-loading="loading"
        @selection-change="whenSelectChanged"
      >
        <el-table-column
          type="selection" width="40" align="center" :reserve-selection="true" fixed="left"
        />
        <el-table-column prop="id" label="id" width="80" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.id" :orderable="true" :column="scope.column" :order="order" @order="onorder" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" min-width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.name" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="album_id" label="专辑id" min-width="80" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.album_id" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="album_name" label="专辑名称" min-width="120" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.album_name" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80" align="center">
          <template v-slot:header="scope">
            <filterable
              :filterable="true" :filter-value="filters.status" :column="scope.column"
              input-type="options" :options="statusOpts"
              @filter="onfilter"
            />
          </template>
          <template v-slot:default="scope">
            <div :class="`status-${scope.row.status}`">{{scope.row.statusText}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="task_state" label="AI自动化状态" width="100" align="center"></el-table-column>
        <el-table-column prop="audit_status" label="审核状态" width="100" align="center">
          <template v-slot:header="scope">
            <filterable
              :filterable="true" :filter-value="filters.audit_status" :column="scope.column"
              input-type="options" :options="auditStatusOpts"
              @filter="onfilter"
            />
          </template>
          <template v-slot:default="scope">
            <div>{{scope.row.auditStatusText}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="audit_uid" label="初审员" width="100" align="center">
          <template v-slot:header="scope">
            <filterable
              :filterable="true" :filter-value="filters.audit_uid" :column="scope.column"
              input-type="options" :options="auditUsers"
              @filter="onfilter"
            />
          </template>
          <template v-slot:default="scope">
            <div>{{scope.row.audit_realname}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="audit_confirm_uid" label="复审员" width="100" align="center">
          <template v-slot:header="scope">
            <filterable
              :filterable="true" :filter-value="filters.audit_confirm_uid" :column="scope.column"
              input-type="options" :options="confirmUsers"
              @filter="onfilter"
            />
          </template>
          <template v-slot:default="scope">
            <div>{{scope.row.audit_confirm_realname}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="audit_time" label="审核时间" width="150" align="center"></el-table-column>
        <el-table-column prop="address" label="播放地址" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.address" class="item" :content="scope.row.address">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="cover" label="封面" width="100" align="center">
          <template v-slot:default="scope">
            <img v-viewer class="headimg" :src="scope.row.cover" v-if="scope.row.cover" />
          </template>
        </el-table-column>
        <el-table-column prop="background_picture" label="背景图" width="80" align="center">
          <template v-slot:default="scope">
            <img v-viewer class="headimg" :src="scope.row.background_picture" v-if="scope.row.background_picture" />
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="时长" min-width="60" align="center"></el-table-column>
        <el-table-column prop="tags" label="标签" min-width="120" align="center"></el-table-column>
        <el-table-column prop="age" label="年龄段" min-width="120" align="center"></el-table-column>
        <el-table-column prop="is_ai" label="AI" min-width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.is_ai" inputType="boolean" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="partner_id" label="AI伙伴" min-width="120" align="center">
          <template v-slot:default="scope">
            <div>{{scope.row.partnerName}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ai_category" label="AI类别" min-width="120" align="center">
          <template v-slot:default="scope">
            <div>{{scope.row.categoryName}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="language" label="语言" min-width="120" align="center"></el-table-column>
        <el-table-column prop="intro" label="释义" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.intro" class="item" :content="scope.row.intro">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="write_background" label="背景" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.write_background" class="item" :content="scope.row.write_background">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="write_background_audio" label="背景音频" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.write_background_audio" class="item" :content="scope.row.write_background_audio">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="source_id" label="来源id" width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.source_id" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="source_from" label="来源" width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.source_from" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="original_author_id" label="原作者id" min-width="80" align="center"></el-table-column>
        <el-table-column prop="original_author_name" label="原作者" min-width="80" align="center"></el-table-column>
        <el-table-column prop="write_background" label="歌词" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover trigger="click" v-if="scope.row.lrcs.length > 0" class="item" width="600px">
              <template #reference>
                <el-button link type="primary">查看</el-button>
              </template>

              <el-table :data="scope.row.lrcs" border style="width:100%;">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="language" label="语言" width="60" align="center"></el-table-column>
                <el-table-column prop="text" label="内容" min-width="100" align="center"></el-table-column>
                <el-table-column prop="address" label="地址" min-width="80" align="center"></el-table-column>
                <el-table-column prop="is_default" label="默认" width="60" align="center"></el-table-column>
              </el-table>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="es_title" label="ES标题" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.es_title" class="item" :content="scope.row.es_title">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="es_keywords" label="ES关键字" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.es_keywords" class="item" :content="scope.row.es_keywords">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="es_description" label="ES描述" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.es_description" class="item" :content="scope.row.es_description">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="created_at" label="创建时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template v-slot:default="scope">
            <div class="flex-column">
              <el-button class="row-col-btn" link type="primary" v-if="scope.row.is_ai" @click="toAIDetail(scope.row)">查看AI详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="layout-bbar">
      <page ref="page" :size="page.size" :page="page.page" @change="onpage" />
    </div>

    <task
      v-if="task.visible" v-model:value="task.visible"
      :tracks="task.tracks"
      :ips="ips"
      :categories="aiCategoryOpts"
      :languages="languageOpts"
      @done="whenAITaskStarted"
    />

    <audit
      v-if="audit.visible" v-model:value="audit.visible"
      :tracks="audit.tracks"
      @done="reload"
    />

    <aconfirm
      v-if="aconfirm.visible" v-model:value="aconfirm.visible"
      :tracks="aconfirm.tracks"
      @done="reload"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import page from '@/components/page/index'
import filterable from '@/components/table/header.filterable.vue'
import task from './task'
import audit from './audit'
import aconfirm from './confirm'
const AUDIT_STATUS_WATTING = 0
const AUDIT_STATUS_WATTING_CONFIRM = 1
const AUDIT_STATUS_CONFIRMED = 2

const AUDIT_ROLE_AUDIT = 1
const AUDIT_ROLE_AUDIT_CONFIRM = 2

export default {
  components: { page, filterable, task, audit, aconfirm },

  data() {
    return {
      height: '200px',
      loading: false,
      lrcTasking: false,
      list: [],
      selecteds: [],
      ips: [],
      yesNoOpts: [
        { id: 0, title: '否' },
        { id: 1, title: '是' },
      ],
      aiCategoryOpts: [
        { id: 1, title: '古诗' },
        { id: 2, title: '英语' },
      ],
      languageOpts: [
        { id: 'cn', title: 'cn' },
        { id: 'en', title: 'en' },
      ],
      auditStatusOpts: [
        { id: 0, title: '初审中' },
        { id: 1, title: '复审中' },
        { id: 2, title: '复审通过' },
      ],
      statusOpts: [
        { id: 0, title: '下架' },
        { id: 1, title: '上架' }
      ],
      publishing: false,

      task: {
        visible: false,
        tracks: []
      },

      audit: {
        visible: false,
        tracks: []
      },

      aconfirm: {
        visible: false,
        tracks: []
      },

      confirmUsers: [],
      auditUsers: [],

      syncing: false,
      offlineing: false,
      onlineing: false
    }
  },

  computed: {
    ...mapState({
      resized: state => state.runtime.resized,
      filters: state => state.tableTrack.filters,
      order: state => state.tableTrack.order,
      page: state => state.tableTrack.page,
    })
  },

  async mounted() {
    this.resize()
    await Promise.all([
      await this.reloadDicts(),
      await this.reloadIps()
    ])
    this.reload()
    this.reloadUsers()
  },

  beforeUnmount() {
    this.stopTimer()
  },

  methods: {
    async reloadDicts() {
      const { data } = await this.request({
        method: 'POST',
        url: '/api/admin/sys/dict/options',
        data: {
          keys: ['grade', 'age_group', 'track_ai_category']
        }
      })

      const tag = await this.reloadTags()
      const category = await this.reloadCategories()
      this.aiCategoryOpts = data.track_ai_category.map(p => {
        return {
          ...p,
          id: Number(p.id),
        }
      })

      this.dicts = {
        ...data,
        tag,
        category
      }
    },

    async reloadUsers() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/user/list'
      })

      const users = (data || []).map(p => {
        return {
          title: p.realname,
          ...p
        }
      })

      this.auditUsers = users
        .filter(p => p.roles && p.roles.indexOf(AUDIT_ROLE_AUDIT) !== -1)

      this.confirmUsers = users
        .filter(p => p.roles && p.roles.indexOf(AUDIT_ROLE_AUDIT_CONFIRM) !== -1)
    },

    async submitLrcTaskBySelecteds() {
      const tracks = this.list.filter(p => this.selecteds.indexOf(p.id) !== -1)
      if (tracks.length === 0) return this.$message.error('请勾选需要执行的单曲')
      const yes = await this.confirm('确定要重新生成歌词吗?')
      if (!yes) return
      if (this.lrcTasking) return
      try {
        this.lrcTasking = true
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/lrc/task/submit',
          data: {
            ids: tracks.map(p => p.id)
          }
        })
        this.$message.success('执行完成')
      } finally {
        this.lrcTasking = false
        this.reload()
      }
    },

    async reloadIps() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/ai/ip/list'
      })

      this.ips = data
    },

    async reloadTags() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/options/tags'
      })
      return data
    },

    async reloadCategories() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/options/categories'
      })
      return data
    },

    async publish() {
      if (this.publishing) return
      try {
        this.publishing = true
        const tracks = this.list.filter(p => this.selecteds.indexOf(p.id) !== -1 && (!p.audit_status || p.audit_status === AUDIT_STATUS_WATTING))
        if (tracks.length === 0) return this.$message.error('请勾选需要同步入库的单曲')

        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/publish',
          data: {
            ids: tracks.map(p => p.id)
          }
        })
        this.$message.success('同步入库成功')
      } finally {
        this.publishing = false
      }
    },

    async online() {
      if (this.onlineing) return false
      try {
        this.onlineing = true
        const tracks = this.list.filter(p => this.selecteds.indexOf(p.id) !== -1 && p.status !== 1)
        if (tracks.length === 0) return this.$message.error('没有找到可以执行的单曲')
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/online',
          data: {
            ids: tracks.map(p => p.id)
          }
        })
        this.$message.success('操作成功')
        this.reload()
      } finally {
        this.onlineing = false
      }
    },

    async offline() {
      if (this.offlineing) return
      try {
        this.offlineing = true
        const tracks = this.list.filter(p => this.selecteds.indexOf(p.id) !== -1 && p.status === 1)
        if (tracks.length === 0) return this.$message.error('没有找到可以执行的单曲')

        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/offline',
          data: {
            ids: tracks.map(p => p.id)
          }
        })
        this.$message.success('操作成功')
        this.reload()
      } finally {
        this.offlineing = false
      }
    },

    val2opt(category, vals, dict, titleFeild) {
      if (!vals) return vals
      if (!dict) {
        dict = this.dicts[category]
      }
      if (!dict) return vals

      return vals.map(val => {
        for (const opt of dict) {
          if (opt.id == val) return opt[titleFeild || 'title']
        }

        return val
      }).join('|')
    },

    whenAITaskStarted() {
      setTimeout(() => {
        this.reload(true)
      }, 300)
    },

    async reload(noLoading) {
      try {
        if (noLoading !== true) this.loading = true
        const params = this.$refs.page.params({
          order_by: this.order.orderBy,
          order_type: this.order.orderType,
          ...this.filters
        })
        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/resource/track/list',
          params: params
        })

        this.$refs.page.update(data)
        const selecteds = []
        let running = false
        for (const item of data.data) {
          item.partnerName = item.partner_id ? this.val2opt(null, [item.partner_id], this.ips, 'nickname') : ''
          item.categoryName = item.ai_category ? this.val2opt(null, [item.ai_category], this.aiCategoryOpts) : ''
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated_at = dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')
          item.tags = this.val2opt('tag', item.tags ? item.tags.split(',') : null)
          item.age = this.val2opt('age_group', item.age ? item.age.split(',') : null)
          item.grade = this.val2opt('grade', [item.grade])
          item.auditStatusText = item.audit_status !== null ? this.val2opt(null, [item.audit_status], this.auditStatusOpts) : ''
          item.audit_time = item.audit_time ? dayjs(item.audit_time).format('YYYY-MM-DD HH:mm') : ''
          item.statusText = item.status !== null ? this.val2opt(null, [item.status], this.statusOpts) : ''
          if (item.task_state === 'ing') running = true
          if (this.selecteds.indexOf(item.id) !== -1) {
            selecteds.push(item)
          }
        }
        this.list = data.data
        this.selecteds = selecteds.map(p => p.id)
        this.$nextTick(() => {
          if (selecteds.length > 0) this.$refs.table.toggleRowSelection(selecteds)
        })
        if (running) this.reloadLater()
      } finally {
        if (noLoading !== true) this.loading = false
      }
    },

    async update(row, feild) {
      await this.request({
        method: 'POST',
        url: '/api/admin/resource/ai/track/update',
        data: {
          id: row.id,
          data: {
            [feild]: row[feild]
          }
        }
      })
      this.$message.success('修改成功')
    },

    stopTimer() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = null
    },

    reloadLater() {
      this.stopTimer()
      this.timer = setTimeout(() => this.reload(true), 3000)
    },

    startAITasksBySelecteds() {
      const tracks = this.list.filter(p => this.selecteds.indexOf(p.id) !== -1 && (!p.audit_status || p.audit_status === AUDIT_STATUS_WATTING))
      if (tracks.length === 0) return this.$message.error('没有找到可以执行的单曲')
      this.task.tracks = tracks
      this.task.visible = true
    },

    showAuditBySelecteds() {
      const tracks = this.list.filter(p => this.selecteds.indexOf(p.id) !== -1)
      if (tracks.length === 0) return this.$message.error('请勾选需要初审的单曲')
      for (const track of tracks) {
        if (track.audit_status !== AUDIT_STATUS_WATTING) {
          return this.$message.error(`单曲《${track.name}》的状态不允许提交`)
        }
      }
      this.audit.tracks = tracks
      this.audit.visible = true
    },

    showConfrimBySelecteds() {
      const tracks = this.list.filter(p => this.selecteds.indexOf(p.id) !== -1 && p.audit_status === AUDIT_STATUS_WATTING_CONFIRM)
      if (tracks.length === 0) return this.$message.error('没有找到可以复审的单曲')
      this.aconfirm.tracks = tracks
      this.aconfirm.visible = true
    },

    async syncToProd() {
      if (this.syncing) return
      this.syncing = true
      try {
        const tracks = this.list.filter(p => this.selecteds.indexOf(p.id) !== -1 && (p.audit_status === AUDIT_STATUS_CONFIRMED || !p.is_ai))
        if (tracks.length === 0) return this.$message.error('没有找到可以同步到线上的单曲')

        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/sync',
          data: {
            ids: tracks.map(p => p.id)
          }
        })
        this.$message.success('操作成功')
        this.reload()
      } finally {
        this.syncing = false
      }
    },

    resize() {
      this.height = this.$refs.container.clientHeight
    },

    onpage({ page, size }) {
      this.$store.commit('tableTrack/page', { page, size })
      this.reload()
    },

    onorder({ orderBy, orderType }) {
      this.$store.commit('tableTrack/order', { orderBy, orderType })
      this.reload()
    },

    onfilter({ id, value }) {
      this.$store.commit('tableTrack/filter', { [id]: value })
      this.reload()
    },

    whenSelectChanged(selection) {
      this.selecteds = selection.map(p => p.id)
    },

    toAIDetail(item) {
      this.$router.push(`/p/resource/track/ai/index/${item.id}/info`)
    }
  }
}
</script>

<style>
.headimg{
  max-width:60px;
  max-height:60px;
}
</style>
