<template>
  <div>
    <el-dialog title="执行AI自动化" v-model="val" width="460px" :destroy-on-close="true" @closed="closed">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120">
          <el-form-item label="AI伙伴" prop="partner_id" class="auf-item">
            <el-select v-model="form.partner_id">
              <el-option v-for="(item, inx) in ips" :key="inx" :label="item.nickname" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="AI类别" prop="ai_category" class="auf-item">
            <el-select v-model="form.ai_category">
              <el-option v-for="(item, inx) in categories" :key="inx" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="语言" prop="language" class="auf-item">
            <el-select v-model="form.language">
              <el-option v-for="(item, inx) in languages" :key="inx" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="初审用户" prop="audit_uid" class="auf-item">
            <el-select v-model="form.audit_uid" @change="whenUserChanged">
              <el-option v-for="(item, inx) in users" :key="inx" :label="item.realname" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="flex-center">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="saving" @click="submit">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/commons/popup.mixin.js'
import { mapState } from 'vuex'
const AUDIT_ROLE_AUDIT = 1

export default {
  mixins: [mixin],

  props: {
    tracks: {
      type: Array,
      default() {
        return []
      }
    },

    ips: {
      type: Array,
      default() {
        return []
      }
    },

    categories: {
      type: Array,
      default() {
        return []
      }
    },

    languages: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      form: {
        partner_id: null,
        ai_category: null,
        language: null,
        audit_uid: null
      },
      rules: {
        partner_id: { required: true, message: '请选择AI伙伴' },
        ai_category: { required: true, message: '请选择AI类别' },
        language: { required: true, message: '请选择语言' },
        audit_uid: { required: true, message: '请选择初审用户' },
      },
      saving: false,
      users: []
    }
  },

  computed: {
    ...mapState({
      lastAuditUid: state => state.tableTrack.lastAuditUid
    })
  },

  created() {
    for (const track of this.tracks) {
      if (track.partner_id && !this.form.partner_id) this.form.partner_id = track.partner_id
      if (track.ai_category && !this.form.ai_category) this.form.ai_category = track.ai_category
      if (track.language && !this.form.language) this.form.language = track.language
    }

    if (!this.form.partner_id && this.ips.length > 0) this.form.partner_id = this.ips[0].id
    if (!this.form.ai_category && this.categories.length > 0) this.form.ai_category = this.categories[0].id
    if (!this.form.language && this.languages.length > 0) this.form.language = this.languages[0].id
    this.reloadUsers()
  },

  methods: {
    async reloadUsers() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/user/list'
      })
      this.users = (data || [])
        .filter(p => p.roles && p.roles.indexOf(AUDIT_ROLE_AUDIT) !== -1)

      let defaultUid = null
      if (this.users.length > 0) {
        if (this.lastAuditUid) {
          for (const u of this.users) {
            if (u.id === this.lastAuditUid) {
              defaultUid = u.id
              break
            }
          }
        }
        if (!defaultUid) defaultUid = this.users[0].id
      }
      if (defaultUid) this.form.audit_uid = defaultUid
    },

    whenUserChanged() {
      this.$store.commit('tableTrack/lastAuditUid', this.form.audit_uid)
    },

    submit() {
      this.$refs.form.validate((yes) => {
        if (yes) this.doSubmit()
      })
    },

    async doSubmit() {
      if (this.saving) return
      try {
        this.saving = true

        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/run',
          data: {
            ids: this.tracks.map(p => p.id),
            feilds: {
              is_ai: 1,
              ...this.form
            }
          }
        })
        this.$emit('done')
        this.$message.success('提交成功，请稍后，每首单曲大概需要3分钟')
        this.close()
      } finally {
        this.saving = false
      }
    },
  }
}
</script>

<style>
.import-form-upload{
  width:100%;
}

.import-form-upload-box{
  width:400px;
  height:200px;
}
</style>
